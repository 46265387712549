import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const SelectionPage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '150px', backgroundColor: 'lightblue', height: '100vh', padding: '20px' }}>
      <h1 style={{ color: 'purple', fontSize: '79px' }}>Welcome, Colton!</h1>
      <Button type="primary" onClick={() => navigate('/galleryeditor')} style={{ margin: '20px' }}>
        Go to Gallery Editor
      </Button>
      <Button type="primary" onClick={() => navigate('/printseditor')} style={{ margin: '20px' }}>
        Go to Prints Editor
      </Button>
    </div>
  );
};

export default SelectionPage;
