import React, { createContext, useState, useContext } from 'react';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (item) => {
    setCart((prevCart) => [...prevCart, item]);
    console.log('Cart updated:', [...cart, item]); // Debugging
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter(item => item.printId !== itemId));
  };

  const updateCartItem = (itemId, newQuantity) => {
    setCart((prevCart) => prevCart.map(item =>
      item.printId === itemId ? { ...item, quantity: newQuantity } : item
    ));
  };

  const getCartCount = () => {
    return cart.reduce((count, item) => count + item.quantity, 0);
  };

  const getCartTotal = () => {
    return cart.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateCartItem, getCartCount, getCartTotal }}>
      {children}
    </CartContext.Provider>
  );
};
