// client/src/components/CategoryGallery.jsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'antd';
import Masonry from 'react-masonry-css';
import '../components/css/CategoryGallery.css';
import { fetchCategory, fetchCategoryImages, getImageUrl } from '../api';

const CategoryGallery = () => {
  const { category } = useParams();
  const [categoryName, setCategoryName] = useState('');
  const [images, setImages] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const categoryData = await fetchCategory(category);
        setCategoryName(categoryData.name);

        const imagesData = await fetchCategoryImages(category);
        const sortedImages = imagesData.sort((a, b) => a.metadata.order - b.metadata.order);
        setImages(sortedImages);
      } catch (err) {
        console.error('Error fetching category data', err);
      }
    };

    fetchCategoryData();
  }, [category]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showPrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <div className="category-gallery">
      <h1>{categoryName}</h1>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, index) => (
          <div key={image._id} onClick={() => handleImageClick(index)}>
            <img
              alt={image.metadata.description}
              src={getImageUrl(image.filename)}
            />
          </div>
        ))}
      </Masonry>
      <Modal
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        className="image-modal"
        width="75vw"
        centered
      >
        <Button className="nav-button left" onClick={showPrevImage}>←</Button>
        <img
          alt={images[currentImageIndex]?.metadata.description}
          src={getImageUrl(images[currentImageIndex]?.filename)}
          className="modal-image"
        />
        <Button className="nav-button right" onClick={showNextImage}>→</Button>
      </Modal>
    </div>
  );
};

export default CategoryGallery;
