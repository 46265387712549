import React, { useEffect, useState } from 'react';
import { Layout, Menu, Modal } from 'antd';
import { InstagramOutlined, FacebookOutlined, YoutubeOutlined, LinkedinOutlined, ShoppingCartOutlined, MenuOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useCart } from '../CartContext.jsx';  
import '../css/Header.css';
import logo from '../../assets/Images/logoname.svg';

const { Header } = Layout;
const AppHeader = () => {
  const location = useLocation();
  const [isTop, setIsTop] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const { getCartCount } = useCart();  // Use the context
  useEffect(() => {
    const handleScroll = () => {
      setIsTop(window.scrollY < 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const headerClass = `header ${isTop ? 'top' : ''}`;
  const getSelectedKey = () => {
    if (location.pathname === '/') return '0';
    if (location.pathname.startsWith('/about')) return '1';
    if (location.pathname.startsWith('/photogallery')) return '2';
    if (location.pathname.startsWith('/film')) return '3';
    if (location.pathname.startsWith('/prints')) return '4';
    if (location.pathname.startsWith('/contact')) return '5';
    return '';
  };
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  return (
    <Header className={headerClass}>
    <div className="logo">
    <Link to="/">
    <img src={logo} alt="CameraLogo" className="logo-img" width="190" height="250" />
    </Link>
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>

        <MenuOutlined />
      </div>
      <div className={`nav-menu ${menuVisible ? 'visible' : ''}`}>
        <Menu mode="horizontal" selectedKeys={[getSelectedKey()]} overflowedIndicator={null}>
          <Menu.Item key="1">
            <Link to="/about">ABOUT</Link>
          </Menu.Item>
          <Menu.Item key="2"><Link to="/photogallery">GALLERY</Link></Menu.Item>
          <Menu.Item key="3"><Link to="/film">FILM</Link></Menu.Item>
          <Menu.Item key="4"><Link to="/prints">PRINTS</Link></Menu.Item>
          <Menu.Item key="5"><Link to="/contact">CONTACT</Link></Menu.Item>
        </Menu>
      </div>
      <Modal
        open={menuVisible}
        onCancel={toggleMenu}
        footer={null}
        width="100%"
        bodyStyle={{ padding: 0, backgroundColor: '#212121' }}
      >
        <Menu mode="vertical" selectedKeys={[getSelectedKey()]} style={{ borderRight: 0 }}>
          <Menu.Item key="1" onClick={toggleMenu} style={{ backgroundColor: 'white', color: 'black' }}>
            <Link to="/about" style={{ color: 'black' }}>ABOUT</Link>
          </Menu.Item>
          <Menu.Item key="2" onClick={toggleMenu} style={{ backgroundColor: 'white', color: 'black' }}><Link to="/photogallery" style={{ color: 'black' }}>GALLERY</Link></Menu.Item>
          <Menu.Item key="3" onClick={toggleMenu} style={{ backgroundColor: 'white', color: 'black' }}><Link to="/film" style={{ color: 'black' }}>FILM</Link></Menu.Item>
          <Menu.Item key="4" onClick={toggleMenu} style={{ backgroundColor: 'white', color: 'black' }}><Link to="/prints" style={{ color: 'black' }}>PRINTS</Link></Menu.Item>
          <Menu.Item key="5" onClick={toggleMenu} style={{ backgroundColor: 'white', color: 'black' }}><Link to="/contact" style={{ color: 'black' }}>CONTACT</Link></Menu.Item>
        </Menu>
      </Modal>
      <div className="nav-icons">
        <Menu mode="horizontal" overflowedIndicator={null}>
          <Menu.Item key="8" className="mobile-hidden" icon={<InstagramOutlined />}>
            <a href="https://www.instagram.com/colton.mansavage/" target="_blank" rel="noopener noreferrer"> </a>
          </Menu.Item>
          <Menu.Item key="9" className="mobile-hidden" icon={<FacebookOutlined />}>
            <a href="https://www.facebook.com/coltonmansavage" target="_blank" rel="noopener noreferrer"> </a>
          </Menu.Item>
          <Menu.Item key="10" className="mobile-hidden" icon={<YoutubeOutlined />}>
            <a href="https://www.youtube.com/channel/UC7LFhwX5BC_W7jvXj5vJS8w" target="_blank" rel="noopener noreferrer"> </a>
          </Menu.Item>
          <Menu.Item key="11" className="mobile-hidden" icon={<LinkedinOutlined />}>
            <a href="https://www.linkedin.com/in/colton-mansavage-960625195/" target="_blank" rel="noopener noreferrer"> </a>
          </Menu.Item>
          <Menu.Item key="12">
            <Link to="/cart">
              <ShoppingCartOutlined />
              <span>{getCartCount()}</span>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    </Header>
  );
};
export default AppHeader;