// src/pages/Login.jsx
import React, { useState, useContext } from 'react';
import { Button, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === 'admin' && password === 'ilovecamera!') { // Simple authentication check
      login();
      navigate('/selection'); // Navigate to the selection page
      message.success('Login successful');
    } else {
      message.error('Invalid username or password');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '250px' }}>
      <h1 style={{ color: 'white', fontSize: '60px' }}>Login</h1>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Input
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ marginBottom: '20px', width: '300px', fontSize: '18px', padding: '10px' }}
        />
        <Input.Password
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: '20px', width: '300px', fontSize: '18px', padding: '10px' }}
        />
        <Button type="primary" onClick={handleLogin} style={{ fontSize: '20px', padding: '10px 25px' }}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
