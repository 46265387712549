import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Modal, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SaveOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import '../components/css/PhotoGallery.css';
import '../components/css/GalleryEditor.css';
import api from '../api';

const { Option } = Select;

const ItemType = {
  CARD: 'card',
};

const DraggableCard = ({ category, index, moveCard, handleCategoryClick }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: ItemType.CARD,
    hover: (item) => {
      if (item.index !== index) {
        moveCard(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CARD,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Col ref={ref} xs={24} sm={12} md={8} lg={8} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Card
        hoverable
        onClick={() => handleCategoryClick(category)}
        cover={
          category.thumbnail ? (
            <img
              alt={category.name}
              src={api.getImageUrl(category.thumbnail)}
            />
          ) : (
            <div className="placeholder">No Image</div>
          )
        }
        style={{ height: '100%' }} // Ensure card is square
      >
        <Card.Meta title={category.name} />
      </Card>
    </Col>
  );
};

const GalleryEditor = () => {
  const [categories, setCategories] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await api.fetchCategories();
        const sortedCategories = res.sort((a, b) => a.order - b.order);
        setCategories(sortedCategories);
      } catch (err) {
        console.error('Error fetching categories', err);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    navigate(`/categoryeditor/${category._id}`);
  };

  const moveCard = (fromIndex, toIndex) => {
    const updatedCategories = [...categories];
    const [movedCategory] = updatedCategories.splice(fromIndex, 1);
    updatedCategories.splice(toIndex, 0, movedCategory);
    setCategories(updatedCategories);
  };

  const handleSaveOrder = async () => {
    try {
      await api.saveCategoryOrder(categories);
      alert('Order saved successfully');
    } catch (err) {
      console.error('Error saving order', err);
    }
  };

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateOk = async () => {
    setIsCreateModalVisible(false);
    if (newCategoryName) {
      try {
        const newCategory = await api.createCategory(newCategoryName);
        setCategories([...categories, newCategory]);
        setNewCategoryName('');
      } catch (err) {
        console.error('Error creating category', err);
      }
    }
  };

  const handleCreateCancel = () => {
    setIsCreateModalVisible(false);
    setNewCategoryName('');
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteOk = async () => {
    setIsDeleteModalVisible(false);
    if (selectedCategory) {
      try {
        await api.deleteCategory(selectedCategory);
        setCategories(categories.filter(category => category._id !== selectedCategory));
        setSelectedCategory(null);
      } catch (err) {
        console.error('Error deleting category', err);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedCategory(null);
  };

  return (
    <div className="gallery-editor">
      <div className="header-container">
        <h1 className="header-title">Gallery Editor</h1>
        <div className="button-group">
          <Button type="primary" icon={<PlusOutlined />} onClick={showCreateModal}>
            Create New Category
          </Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={handleSaveOrder}>
            Save Order
          </Button>
          <Button type="primary" icon={<DeleteOutlined />} onClick={showDeleteModal}>
            Delete Category
          </Button>
        </div>
      </div>
      <Modal title="Create New Category" visible={isCreateModalVisible} onOk={handleCreateOk} onCancel={handleCreateCancel}>
        <Input
          placeholder="Enter new category name"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
      </Modal>
      <Modal title="Delete Category" visible={isDeleteModalVisible} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
        <Select
          placeholder="Select a category to delete"
          style={{ width: '100%' }}
          value={selectedCategory}
          onChange={setSelectedCategory}
        >
          {categories.map(category => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>
      </Modal>
      <DndProvider backend={HTML5Backend}>
        <Row gutter={[16, 16]}>
          {categories.map((category, index) => (
            <DraggableCard
              key={category._id}
              index={index}
              category={category}
              moveCard={moveCard}
              handleCategoryClick={handleCategoryClick}
            />
          ))}
        </Row>
      </DndProvider>
    </div>
  );
};

export default GalleryEditor;
