import React from 'react';
import '../css/HeroSection.css';

const HeroSection = () => (
  <div className="hero-section">
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/XFCD4629U3I?autoplay=1"
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="Hero Video"
    ></iframe>
  </div>
);

export default HeroSection;
