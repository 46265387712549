import React from 'react';
import '../css/ServicesSection.css';
import FilmmakingImage from '../../assets/Images/Video.png';
import StillPhotographyImage from '../../assets/Images/stills.png';
import VideoEditingImage from '../../assets/Images/Editing.png';
import CreativeCollabImage from '../../assets/Images/CreativeCollab.png';
import { CameraOutlined } from '@ant-design/icons';

const serviceDetails = [
  {
    title: "Filmmaking",
    image: FilmmakingImage,
    description: "Need a video for your company? I have experience creating both short and long-form videos that capture the essence of adventure! I'm also available for hire on film sets as a Production Asssisant, Grip, and Assistant Camera."
  },
  {
    title: "Still Photography",
    image: StillPhotographyImage,
    description: "From breathtaking landscapes to timeless portraits, my photography aims to capture the spirit of adventure."
  },
  {
    title: "Video Editing",
    image: VideoEditingImage,
    description: "I am an experienced video editor capable of turning raw footage into polished narratives that convey emotion and authenticity."
  },
  {
    title: "Brand Partnerships",
    image: CreativeCollabImage,
    description: "I love partnering with outdoor brands to develop authentic media strategies that resonate with their audience. I bring my passion for storytelling and expertise in photography and filmmaking to create content that drives engagement and inspires action."
  }
];

const ServicesSection = () => {
  const [flipped, setFlipped] = React.useState(Array(serviceDetails.length).fill(false));

  const handleFlip = (index) => {
    const newFlipped = [...flipped];
    newFlipped[index] = !newFlipped[index];
    setFlipped(newFlipped);
  };

  return (
    <div className="services-section">
      <div className="rolling-banner">
        <div className="banner-content">
          {Array(20).fill(null).map((_, idx) => (
            <React.Fragment key={idx}>
              <span className="wave-item"><CameraOutlined style={{ fontSize: '40px', margin: '0 10px' }} /></span>
              <span className="wave-item">SERVICES   </span>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="services-grid">
        {serviceDetails.map((service, index) => (
          <div
            key={index}
            className={`service-item ${flipped[index] ? 'flipped' : ''}`}
            style={{ backgroundImage: `url(${service.image})` }}
            onClick={() => handleFlip(index)}
          >
            <div className="overlay">
              <h3>{service.title}</h3>
            </div>
            <div className="back">
              <div className="overlay">
                <p>{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
