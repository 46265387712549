import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit'; // Import lazysizes and plugins
import '../css/Gallery.css';
import photographyImage from '../../assets/Images/Sheep.png';
import videographyImage from '../../assets/Images/Boat.png';

const Gallery = () => {
  const photographyRef = useRef(null);
  const videographyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;

      const handleZoom = (ref) => {
        if (ref.current) {
          const offsetTop = ref.current.offsetTop;
          const offsetHeight = ref.current.offsetHeight;

          if (scrollY + viewportHeight > offsetTop && scrollY < offsetTop + offsetHeight) {
            const percentage = (scrollY + viewportHeight - offsetTop) / (viewportHeight + offsetHeight);
            ref.current.style.transform = `scale(${1 + percentage * 0.2})`;
          }
        }
      };

      handleZoom(photographyRef);
      handleZoom(videographyRef);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="gallery">
      <Link to="/photogallery" className="gallery-item" ref={photographyRef} style={{ backgroundImage: `url(${photographyImage})` }}>
        <div className="overlay">
          <h3>PHOTOS</h3>
        </div>
      </Link>
      <Link to="/film" className="gallery-item" ref={videographyRef} style={{ backgroundImage: `url(${videographyImage})` }}>
        <div className="overlay">
          <h3>FILMS</h3>
        </div>
      </Link>
    </div>
  );
};
export default Gallery;
