import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { fetchPrints, getPrintImageUrl } from '../api';
import '../components/css/PrintsLanding.css';


const PrintsLanding = () => {
  const [prints, setPrints] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getPrints = async () => {
      try {
        const data = await fetchPrints(); // Changed from axios.get to fetchPrints
        setPrints(data);
      } catch (err) {
        console.error('Error fetching prints', err);
      }
    };

    getPrints();
  }, []);

  const handlePrintClick = (printId) => {
    navigate(`/print/${printId}`);
  };

  return (
    <div className="prints-landing">
      <h1 className="title">PRINT SHOP</h1>
      <Row gutter={[16, 16]}>
        {prints.map((print) => (
          <Col key={print._id} xs={24} sm={12} md={8} lg={8}>
            <Card
              hoverable
              className="print-card"
              onClick={() => handlePrintClick(print._id)}
              cover={
                <img
                  alt={print.description}
                  data-src={getPrintImageUrl(print.filename)} // Main image URL
                  onMouseOver={(e) => e.currentTarget.src = getPrintImageUrl(print.mockupFilename)} // Swap to mockup image on hover
                  onMouseOut={(e) => e.currentTarget.src = getPrintImageUrl(print.filename)} // Revert to main image on mouse out
                  className="print-image lazyload"
                />
              }
            >
              <Card.Meta title={print.title} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PrintsLanding;
