import React, { useEffect, useState } from 'react';
import { Button, List, InputNumber, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useCart } from '../components/CartContext.jsx';
import { Link } from 'react-router-dom';
import '../components/css/CartPage.css';
import api from '../api';

const CartPage = () => {
  const { cart, removeFromCart, updateCartItem, getCartTotal } = useCart();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRemove = (printId) => {
    removeFromCart(printId);
    message.success('Item removed from cart');
  };

  const handleQuantityChange = (printId, newQuantity) => {
    updateCartItem(printId, newQuantity);
  };

  const incrementQuantity = (printId, currentQuantity) => {
    updateCartItem(printId, currentQuantity + 1);
  };

  const decrementQuantity = (printId, currentQuantity) => {
    if (currentQuantity > 1) {
      updateCartItem(printId, currentQuantity - 1);
    }
  };

  useEffect(() => {
    cart.forEach(item => {
      console.log(`Printful Product ID: ${item.printfulProductId}`);
      console.log(`Variant ID for size ${item.size}: ${item.printfulVariantId}`);
    });
  }, [cart]);

  return (
    <div className="cart-page">
      <h1 className="cart-title">Shopping Cart</h1>
      <List
        itemLayout="horizontal"
        dataSource={cart}
        renderItem={(item) => (
          <List.Item className="cart-item">
            <img src={api.getPrintImageUrl(item.filename)} alt={item.title} className="cart-item-image" />
            <div className="cart-item-info">
              <h2 className="cart-item-title">{item.title}</h2>
              <p className="cart-item-size">Size: {item.size}</p>
            </div>
            {isMobile ? (
              <div className="cart-item-details">
                <div className="cart-item-quantity">
                  <Button icon={<MinusOutlined />} onClick={() => decrementQuantity(item.printId, item.quantity)} />
                  <InputNumber min={1} value={item.quantity} onChange={(value) => handleQuantityChange(item.printId, value)} />
                  <Button icon={<PlusOutlined />} onClick={() => incrementQuantity(item.printId, item.quantity)} />
                </div>
                <div className="cart-item-price">${(item.price * item.quantity).toFixed(2)}</div>
                <Button type="text" onClick={() => handleRemove(item.printId)} className="cart-item-remove">
                  X
                </Button>
              </div>
            ) : (
              <>
                <div className="cart-item-quantity">
                  <Button icon={<MinusOutlined />} onClick={() => decrementQuantity(item.printId, item.quantity)} />
                  <InputNumber min={1} value={item.quantity} onChange={(value) => handleQuantityChange(item.printId, value)} />
                  <Button icon={<PlusOutlined />} onClick={() => incrementQuantity(item.printId, item.quantity)} />
                </div>
                <div className="cart-item-price">${(item.price * item.quantity).toFixed(2)}</div>
                <Button type="text" onClick={() => handleRemove(item.printId)} className="cart-item-remove">
                  X
                </Button>
              </>
            )}
          </List.Item>
        )}
      />
      <hr className="cart-divider" />
      <div className="cart-summary">
        <div className="cart-subtotal">Subtotal: ${getCartTotal().toFixed(2)}</div>
        <Link to="/checkout">
          <Button type="primary" className="cart-checkout-button">
            Checkout
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CartPage;
