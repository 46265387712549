import React from 'react';
import '../components/css/OrderSuccess.css';
import logo from '../assets/Images/blacklogo.svg'; // Assuming the logo is available in this path

const OrderSuccessPage = () => {
  return (
    <div className="order-success-page">
      <div className="order-success-header">
      </div>
      <div className="order-success-content">
        <h1>Thank You for Your Order!</h1>
        <p>Your order has been placed successfully. Please check your email for order details and tracking information. If you have any questions, feel free to contact us.</p>
      </div>
    </div>
  );
};

export default OrderSuccessPage;
