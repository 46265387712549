import React, { useEffect, useState } from 'react';
import { List, Upload, Button, Input, message, Modal, Collapse, Checkbox } from 'antd';
import { UploadOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import api from '../api';
import '../components/css/PrintsEditor.css';

const { Panel } = Collapse;

const PrintsEditor = () => {
  const [prints, setPrints] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [mockupFileList, setMockupFileList] = useState([]); // State for mockup image

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [printfulProductId, setPrintfulProductId] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedPrints, setSelectedPrints] = useState([]);
  const [descriptions, setDescriptions] = useState({});

  useEffect(() => {
    const fetchPrints = async () => {
      try {
        const res = await api.fetchPrints();
        setPrints(res);
        // Initialize descriptions state with existing print descriptions
        const initialDescriptions = {};
        res.forEach(print => {
          initialDescriptions[print._id] = print.description;
        });
        setDescriptions(initialDescriptions);
      } catch (err) {
        console.error('Error fetching prints', err);
        message.error('Failed to fetch prints');
      }
    };

    fetchPrints();
  }, []);

  const handleUpload = async () => {
    if (!title || !description || !printfulProductId || fileList.length === 0 || mockupFileList.length === 0) {
      message.error('Please fill all fields and select at least one file');
      return;
    }

    const formData = new FormData();
    // Append the main image files
  fileList.forEach((file) => {
    formData.append('files', file.originFileObj);
  });
  // Append the mockup image files to the same 'files' key
  mockupFileList.forEach((file) => {
    formData.append('files', file.originFileObj);
  });
  
  formData.append('title', title);
  formData.append('description', description);
  formData.append('printfulProductId', printfulProductId);
  try {
    const res = await api.uploadPrints(formData);
    message.success(res.message);
    setFileList([]);
    setMockupFileList([]); // Clear mockup file list
    setTitle('');
    setDescription('');
    setPrintfulProductId('');

    // Refresh the prints list
    const printsRes = await api.fetchPrints();
    setPrints(printsRes);
  } catch (err) {
    console.error('Error uploading print', err);
    message.error('Failed to upload print');
  }
};

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };


  const handleMockupFileChange = ({ fileList }) => {
    setMockupFileList(fileList); // Handle mockup file list change
  };


  const handleVariantChange = (e, printId) => {
    const { name, value } = e.target;
    setPrints((prevPrints) =>
      prevPrints.map((print) =>
        print._id === printId
          ? { ...print, variantIds: { ...print.variantIds, [name]: value } }
          : print
      )
    );
  };

  const handleSaveVariants = async (printId) => {
    const print = prints.find((print) => print._id === printId);
    if (!print) {
      message.error('Print not found');
      return;
    }

    try {
      await api.updateVariantIds(printId, print.variantIds);
      message.success('Variant IDs updated successfully');
      // Refresh the prints list
      const printsRes = await api.fetchPrints();
      setPrints(printsRes);
    } catch (err) {
      console.error('Error updating variant IDs', err);
      message.error('Failed to update variant IDs');
    }
  };

  const handleDescriptionChange = (e, printId) => {
    const { value } = e.target;
    setDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [printId]: value,
    }));
  };

  const handleUpdateDescription = async (printId) => {
    const description = descriptions[printId];
    if (!description) {
      message.error('Description cannot be empty');
      return;
    }

    try {
      await api.updatePrintDescription(printId, description);
      message.success('Description updated successfully');
      // Refresh the prints list
      const printsRes = await api.fetchPrints();
      setPrints(printsRes);
    } catch (err) {
      console.error('Error updating description', err);
      message.error('Failed to update description');
    }
  };

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await api.deletePrints(selectedPrints);
      message.success('Prints deleted successfully');
      setDeleteModalVisible(false);
      // Refresh the prints list
      const printsRes = await api.fetchPrints();
      setPrints(printsRes);
      setSelectedPrints([]);
    } catch (err) {
      console.error('Error deleting prints', err);
      message.error('Failed to delete prints');
    }
  };

  const handlePrintSelection = (printId, checked) => {
    setSelectedPrints((prevSelectedPrints) =>
      checked ? [...prevSelectedPrints, printId] : prevSelectedPrints.filter((id) => id !== printId)
    );
  };

  const isVariantIdsSet = (variantIds) => {
    return variantIds && Object.keys(variantIds).length === 5 && Object.values(variantIds).every(id => id);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedPrints = Array.from(prints);
    const [removed] = reorderedPrints.splice(result.source.index, 1);
    reorderedPrints.splice(result.destination.index, 0, removed);

    setPrints(reorderedPrints);
  };

  const handleSaveOrder = async () => {
    try {
      const printsToUpdate = prints.map((print, index) => ({
        _id: print._id,
        order: index,
      }));

      await api.reorderPrints(printsToUpdate);
      message.success('Order updated successfully');
      // Refresh the prints list
      const printsRes = await api.fetchPrints();
      setPrints(printsRes);
    } catch (err) {
      console.error('Error updating order', err);
      message.error('Failed to update order');
    }
  };

  return (
    <div className="prints-editor">
      <div className="header-container">
        <h1 className="header-title">Prints Editor</h1>
        <div className="button-container">
          <Button
            type="primary" icon={<DeleteOutlined />}
            style={{ backgroundColor: 'red', borderColor: 'red' }}
            onClick={showDeleteModal}
          >
            Delete Prints
          </Button>
          <Button
            type="primary" icon={<SaveOutlined />}
            style={{ backgroundColor: 'blue', borderColor: 'blue' }}
            onClick={handleSaveOrder}
          >
            Save Order
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-prints">
          {(provided) => (
            <div
              className="prints-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={prints}
                renderItem={(print, index) => (
                  <Draggable key={print._id} draggableId={print._id} index={index}>
                    {(provided) => (
                      <List.Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="print-card">
                          <img
                            alt={print.description}
                            src={api.getPrintImageUrl(print.filename)}
                            className="print-image"
                          />
                          <div className="print-info">
                            <h3>{print.title}</h3>
                            <Collapse>
                              <Panel
                                header="Variant IDs"
                                key="1"
                                className={isVariantIdsSet(print.variantIds) ? 'variant-ids-set' : 'variant-ids-not-set'}
                              >
                                <Input
                                  placeholder="XS Variant ID"
                                  name="XS"
                                  value={print.variantIds?.XS || ''}
                                  onChange={(e) => handleVariantChange(e, print._id)}
                                />
                                <Input
                                  placeholder="S Variant ID"
                                  name="S"
                                  value={print.variantIds?.S || ''}
                                  onChange={(e) => handleVariantChange(e, print._id)}
                                />
                                <Input
                                  placeholder="M Variant ID"
                                  name="M"
                                  value={print.variantIds?.M || ''}
                                  onChange={(e) => handleVariantChange(e, print._id)}
                                />
                                <Input
                                  placeholder="L Variant ID"
                                  name="L"
                                  value={print.variantIds?.L || ''}
                                  onChange={(e) => handleVariantChange(e, print._id)}
                                />
                                <Input
                                  placeholder="XL Variant ID"
                                  name="XL"
                                  value={print.variantIds?.XL || ''}
                                  onChange={(e) => handleVariantChange(e, print._id)}
                                />
                                <Button type="primary" onClick={() => handleSaveVariants(print._id)} style={{ marginTop: '10px' }}>
                                  Save Variants
                                </Button>
                              </Panel>
                              <Panel header="Description" key="2">
                                <Input.TextArea
                                  placeholder="Edit Description"
                                  value={descriptions[print._id] || ''}
                                  onChange={(e) => handleDescriptionChange(e, print._id)}
                                  rows={4}
                                />
                                <Button type="primary" onClick={() => handleUpdateDescription(print._id)} icon={<SaveOutlined />}>
                                  Update Description
                                </Button>
                              </Panel>
                            </Collapse>
                          </div>
                        </div>
                      </List.Item>
                    )}
                  </Draggable>
                )}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="upload-form">
        <Input
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Input
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Input
          placeholder="Printful Product ID"
          value={printfulProductId}
          onChange={(e) => setPrintfulProductId(e.target.value)}
        />
        <Upload
          fileList={fileList}
          onChange={handleFileChange}
          multiple
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Select Files</Button>
        </Upload>
        <Upload
          fileList={mockupFileList} // Mockup image file list
          onChange={handleMockupFileChange} // Handle mockup file change
          multiple={false} // Only one mockup image
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Select Mockup Image</Button>
        </Upload>
        <Button type="primary" onClick={handleUpload} style={{ marginTop: '10px' }}>
          Upload
        </Button>
      </div>
      <Modal
        title="Select Images to Delete"
        visible={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <List
          dataSource={prints}
          renderItem={(print) => (
            <List.Item>
              <Checkbox
                onChange={(e) => handlePrintSelection(print._id, e.target.checked)}
              >
                <img
                  alt={print.description}
                  src={api.getPrintImageUrl(print.filename)}
                  className="print-image"
                  style={{ width: '50px', marginRight: '10px' }}
                />
                {print.title}
              </Checkbox>
            </List.Item>
          )}
        />
        <Button type="primary" onClick={handleDelete} style={{ marginTop: '10px' }}>
          Delete {selectedPrints.length} Images
        </Button>
      </Modal>
    </div>
  );
};

export default PrintsEditor;
