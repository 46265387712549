// client/src/api.js

import axios from 'axios';

const BASE_URL = 'https://www.mansavage.media/api'; // Updated

export const fetchPrints = async () => {
  const response = await axios.get(`${BASE_URL}/prints`);
  return response.data;
};

export const fetchCategories = async () => {
  const response = await axios.get(`${BASE_URL}/gallery/categories`);
  return response.data;
};

export const fetchCategory = async (categoryId) => {
  const response = await axios.get(`${BASE_URL}/gallery/categories/${categoryId}`);
  return response.data;
};

export const fetchCategoryImages = async (categoryId) => {
  const response = await axios.get(`${BASE_URL}/gallery/images/${categoryId}`);
  return response.data;
};

export const getImageUrl = (filename) => {
  return `${BASE_URL}/gallery/image/${filename}`;
};

// New function for getting print image URLs
export const getPrintImageUrl = (filename) => {
    return `${BASE_URL}/prints/image/${filename}`;
  };



// New functions for gallery editor
export const saveCategoryOrder = async (categories) => {
    const response = await axios.post(`${BASE_URL}/admin/save-order`, { categories });
    return response.data;
  };
  
  export const createCategory = async (name) => {
    const response = await axios.post(`${BASE_URL}/admin/categories`, { name });
    return response.data;
  };
  
  export const deleteCategory = async (id) => {
    const response = await axios.delete(`${BASE_URL}/admin/categories/${id}`);
    return response.data;
  };

  // New functions for category editor
export const fetchImages = async (categoryId) => {
    const response = await axios.get(`${BASE_URL}/gallery/images/${categoryId}`);
    return response.data;
  };
  
  export const uploadImages = async (formData) => {
    const response = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  };
  
  export const saveImageOrder = async (images) => {
    const response = await axios.post(`${BASE_URL}/admin/reorder`, { images });
    return response.data;
  };
  
  export const deleteImages = async (imageIds) => {
    const response = await axios.delete(`${BASE_URL}/admin/delete`, {
      data: { imageIds },
    });
    return response.data;
  };
  
  export const setThumbnail = async (categoryId, thumbnailId) => {
    const response = await axios.post(`${BASE_URL}/admin/set-thumbnail`, {
      categoryId,
      thumbnailId,
    });
    return response.data;
  };

  // New functions for prints editor
export const uploadPrints = async (formData) => {
    const response = await axios.post(`${BASE_URL}/prints/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  };
  
  export const updateVariantIds = async (id, variantIds) => {
    const response = await axios.put(`${BASE_URL}/prints/variant-ids/${id}`, { variantIds });
    return response.data;
  };
  
  export const fetchPrintById = async (id) => {
    const response = await axios.get(`${BASE_URL}/prints/${id}`);
    return response.data;
  };
  
  export const deletePrints = async (ids) => {
    const response = await axios.delete(`${BASE_URL}/prints/delete`, { data: { ids } });
    return response.data;
  };
  
  export const updatePrintDescription = async (id, description) => {
    const response = await axios.put(`${BASE_URL}/prints/description/${id}`, { description });
    return response.data;
  };
  
  export const reorderPrints = async (prints) => {
    const response = await axios.post(`${BASE_URL}/prints/reorder`, { prints });
    return response.data;
  };

export default {
    fetchPrints,
    fetchCategories,
    fetchCategory,
    fetchCategoryImages,
    getImageUrl,
    getPrintImageUrl, // Export the new function
    saveCategoryOrder,
    createCategory,
    deleteCategory,
    fetchImages,
    uploadImages,
    saveImageOrder,
    deleteImages,
    setThumbnail,
    uploadPrints,
    updateVariantIds,
    fetchPrintById,
    deletePrints,
    updatePrintDescription,
    reorderPrints,
  };