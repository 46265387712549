import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Card, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../components/css/PhotoGallery.css';
import { fetchCategories, getImageUrl } from '../api';

const LazyCard = lazy(() => import('../components/LazyCard')); // Assuming LazyCard is a component

const PhotoGallery = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndSetCategories = async () => {
      try {
        const data = await fetchCategories();
        const sortedCategories = data.sort((a, b) => a.order - b.order);
        setCategories(sortedCategories);
      } catch (err) {
        console.error('Error fetching categories', err);
      }
    };

    fetchAndSetCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    navigate(`/gallery/${categoryId}`);
  };

  return (
    <div className="photo-gallery">
      <div className="photo-hero">
        <h1>PHOTOGRAPHY</h1>
      </div>
      <Row gutter={[16, 16]}>
        {categories.map((category) => (
          <Col key={category._id} xs={24} sm={12} md={8} lg={8}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyCard
                category={category}
                handleCategoryClick={handleCategoryClick}
                imageWidth={300}  // Example width
                imageHeight={200} // Example height
              />
            </Suspense>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PhotoGallery;
