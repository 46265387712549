// components/ContactCTA.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../css/ContactCTA.css'; // Import your CSS

const ContactCTA = () => {
  return (
    <div className="contact-cta">
      <p className="cta-message">Working on something exciting? Let's collaborate!</p>
      <Link to="/contact" className="contact-button">
        Get in touch!
      </Link>
    </div>
  );
};

export default ContactCTA;
