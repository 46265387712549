import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../components/css/ContactPage.css';
import ContactImage from '../assets/Images/Asia.png';
import SideImage from '../assets/Images/moto.png';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      phone: formData.phone,
      message: formData.message
    };

    emailjs.send(
      'service_l1e90ih', // Replace with your EmailJS service ID
      'template_s6jvepv', // Replace with your EmailJS template ID
      templateParams,
      '-KEwzVUqW4JX1hXEA' // Replace with your EmailJS user ID (public key)
    ).then(() => {
      setSubmitted(true);
    }).catch((error) => {
      console.error('Failed to send email:', error);
    });
  };

  return (
    <div className="contact-page">
      <div className="contact-hero" style={{ backgroundImage: `url(${ContactImage})` }}>
        <h1>CONTACT</h1>
      </div>
      <div className="contact-content">
        <img src={SideImage} alt="Contact" className="contact-image" />
        <div className="contact-text">
          <h2>Got a project or idea in mind? Reach out!</h2>
          {submitted ? (
            <p className="submission-message">We've received your message. Thanks for reaching out and we will be with you in 1-2 business days!</p>
          ) : (
            <form className="contact-form" onSubmit={handleSubmit}>
              <label>
                Name <span className="required">*</span>
                <input type="text" name="name" required value={formData.name} onChange={handleChange} placeholder="" />
              </label>
              <label>
                Email Address <span className="required">*</span>
                <input type="email" name="email" required value={formData.email} onChange={handleChange} placeholder="" />
              </label>
              <label>
                Phone Number <span className="required">*</span>
                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="" />
              </label>
              <label>
                Message <span className="required">*</span>
                <textarea name="message" required value={formData.message} onChange={handleChange} placeholder="Tell me a bit about your idea or project!"></textarea>
              </label>
              <button type="submit">Submit</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
