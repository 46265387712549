import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
/*Display Import Routes*/
import AppHeader from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import FilmPage from './pages/Film';
import PhotoGallery from './pages/PhotoGallery';
import CategoryGallery from './pages/CategoryGallery';
import OrderSuccessPage from './pages/OrderSuccess';

/*Shopping Routes*/
import PrintsLandingPage from './pages/PrintsLanding';
import PrintDetailPage from './pages/PrintsDetails';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/Checkout';
import { CartProvider } from '../src/components/CartContext';
// Colton Specific
import GalleryEditor from './pages/GalleryEditor';
import CategoryEditor from './pages/CategoryEditor';
import PrintsEditor from './pages/PrintsEditor';
import LoginPage from './pages/Login';
import SelectionPage from './pages/SelectionPage'; // Import the selection page

import { AuthProvider } from './components/AuthContext';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute


import './App.css';

const { Content } = Layout;

const App = () => {
  return (
    <Router>
      <CartProvider>
        <AuthProvider>
          <Routes>
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route
              path="*"
              element={
                <Layout className="layout">
                  <AppHeader />
                  <Content className="layout-content">
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/contact" element={<ContactPage />} />
                      <Route path="/film" element={<FilmPage />} />
                      <Route path="/photogallery" element={<PhotoGallery />} />
                      <Route path="/gallery/:category" element={<CategoryGallery />} />
                      <Route path="/prints" element={<PrintsLandingPage />} />
                      <Route path="/print/:printId" element={<PrintDetailPage />} />
                      <Route path="/login" element={<LoginPage />} />
                      <Route path="/selection" element={<PrivateRoute element={SelectionPage} />} />
                      <Route path="/galleryeditor" element={<PrivateRoute element={GalleryEditor} />} />
                      <Route path="/categoryeditor/:categoryId" element={<PrivateRoute element={CategoryEditor} />} />
                      <Route path="/printseditor" element={<PrivateRoute element={PrintsEditor} />} />
                      <Route path="/cart" element={<CartPage />} />
                      <Route path="/ordersuccess" element={<OrderSuccessPage />} />

                    </Routes>
                  </Content>
                  <Footer />
                </Layout>
              }
            />
          </Routes>
        </AuthProvider>
      </CartProvider>
    </Router>
  );
};

export default App;