import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Input, Button, List, InputNumber, message, Radio, Form, Row, Col } from 'antd';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

import { useCart } from '../components/CartContext.jsx';
import '../components/css/CheckoutPage.css';
import logo from '../assets/Images/blacklogo.svg';
import api from '../api';

const stripePromise = loadStripe('pk_test_51Pe902IQ945L6uaecxLjpNitkeU8sWluCRTAdNksDbFdf0UDNRGbT2P9yG4o4hGjO7i5GrQmK1gcPLcPeSSu35eJ00PLbeY9LJ');

const CheckoutForm = ({ shippingInfo, taxAmount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { cart, getCartTotal } = useCart();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, firstName, lastName, address1, address2, city, state, zip, phoneNumber } = shippingInfo;
    const customerInfo = { 
      name: `${firstName} ${lastName}`, 
      address1, 
      address2, 
      city, 
      state, 
      country: 'US', 
      zip, 
      phone: phoneNumber, 
      email 
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {
      const { id } = paymentMethod;
      try {
        const response = await axios.post('/api/checkout', {
          cart,
          payment_method: id,
          customerEmail: email,
          customerInfo
        });

        // Send email to site owner with order details
        const printDetails = cart.map(item => `${item.title} in ${item.size}`).join(', ');
        const templateParams = {
          print_name: printDetails,
          print_size: '',
          customer_name: customerInfo.name,
          customer_address: `${customerInfo.address1}, ${customerInfo.address2}, ${customerInfo.city}, ${customerInfo.state}, ${customerInfo.zip}`,
          customer_email: customerInfo.email
        };

        // Send email to site owner
        emailjs.send(
          'service_tcgxc3d', // Replace with your EmailJS service ID
          'template_al9ea6l', // Replace with your EmailJS template ID
          templateParams,
          'E3Fs6JzPEXVsn-7QF' // Replace with your EmailJS user ID (public key)
        ).then(() => {
          console.log('Order notification email sent');
        }).catch((error) => {
          console.error('Failed to send order notification email:', error);
        });

        // Send email to customer
        const customerTemplateParams = {
          ...templateParams,
          logo_url: logo, // Assuming `logo` is the URL to your logo image
          total_amount: (getCartTotal() + taxAmount + 15).toFixed(2),
          print_image_url: cart[0] ? api.getPrintImageUrl(cart[0].filename) : '' // Assuming one print per order
        };

        emailjs.send(
          'service_tcgxc3d', // Replace with your second EmailJS service ID
          'template_xgvozsi', // Replace with your EmailJS customer template ID
          customerTemplateParams,
          'E3Fs6JzPEXVsn-7QF' // Replace with your second EmailJS user ID (public key)
        ).then(() => {
          console.log('Customer confirmation email sent');
        }).catch((error) => {
          console.error('Failed to send customer confirmation email:', error);
        });

        // Send order to Printful
        await axios.post('/api/printful', { cart, customerInfo });

        navigate('/ordersuccess'); // Redirect to order success page
      } catch (error) {
        console.error('Error:', error);
        message.error('Payment failed. Please try again.');
      }
    } else {
      console.error(error);
      message.error('Payment failed. Please try again.');
    }
  };

  const totalAmount = getCartTotal() + taxAmount + 15;

  return (
    <form onSubmit={handleSubmit}>
      <div className="payment-section">
        <CardElement />
        <Button type="primary" htmlType="submit" disabled={!stripe}>
          Pay ${totalAmount.toFixed(2)} with Secure SSL Checkout
        </Button>
      </div>
    </form>
  );
};

const CheckoutPage = () => {
  const { cart, updateCartItem, removeFromCart, getCartTotal } = useCart();
  const [form] = Form.useForm();
  const [shippingInfo, setShippingInfo] = useState({});
  const [isShippingInfoCompleted, setIsShippingInfoCompleted] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('flatRate');
  const [taxAmount, setTaxAmount] = useState(0);

  const handleInputChange = (changedValues, allValues) => {
    setShippingInfo(allValues);
  };

  const calculateTax = (zip) => {
    let taxRate = 0.07;
    if (zip.startsWith('9')) {
      taxRate = 0.08;
    }
    const tax = getCartTotal() * taxRate;
    setTaxAmount(tax);
  };

  const handleShippingInfoSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setShippingInfo(values);
        calculateTax(values.zip);
        setIsShippingInfoCompleted(true);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleQuantityChange = (printId, newQuantity) => {
    updateCartItem(printId, newQuantity);
  };

  const handleRemove = (printId) => {
    removeFromCart(printId);
  };

  return (
    <div className="checkout-page">
      <div className="checkout-header">
        <img src={logo} alt="Logo" className="checkout-logo" />
      </div>
      <div className="checkout-container">
        <div className="checkout-form">
          <Form
            layout="vertical"
            form={form}
            initialValues={shippingInfo}
            onValuesChange={handleInputChange}
            onFinish={handleShippingInfoSubmit}
          >
            <h2 className="section-title">1. Shipping Info</h2>
            <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
              <Input placeholder="Your email" />
            </Form.Item>
            <h3 className="section-title">Shipping Address</h3>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="firstName" rules={[{ required: true, message: 'Please enter your first name' }]}>
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="lastName" rules={[{ required: true, message: 'Please enter your last name' }]}>
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="address1" rules={[{ required: true, message: 'Please enter your address' }]}>
              <Input placeholder="Address 1" />
            </Form.Item>
            <Form.Item name="address2">
              <Input placeholder="Address 2" />
            </Form.Item>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="zip" rules={[{ required: true, message: 'Please enter your ZIP code' }]}>
                  <Input placeholder="ZIP Code" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="city" rules={[{ required: true, message: 'Please enter your city' }]}>
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="state" rules={[{ required: true, message: 'Please enter your state' }]}>
                  <Input placeholder="State" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="phoneNumber" rules={[{ required: true, message: 'Please enter your phone number' }]}>
              <Input placeholder="Phone Number" />
            </Form.Item>
            <Button type="primary" htmlType="submit">Continue</Button>
          </Form>

          {isShippingInfoCompleted && (
            <>
              <div>
                <h2 className="section-title">2. Delivery Methods</h2>
                <div className="delivery-method-section">
                  <Radio.Group onChange={e => setDeliveryMethod(e.target.value)} value={deliveryMethod}>
                    <Radio value="flatRate">Flat Rate - $15.00</Radio>
                  </Radio.Group>
                </div>

                <div>
                  <h2 className="section-title">3. Payment & Discounts</h2>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm shippingInfo={shippingInfo} taxAmount={taxAmount} />
                  </Elements>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="order-summary">
          <h2>Order Summary</h2>
          <List
            itemLayout="horizontal"
            dataSource={cart}
            renderItem={(item) => (
              <List.Item>
                <img src={api.getPrintImageUrl(item.filename)} alt={item.title} className="order-summary-image" />
                <div className="order-summary-info">
                  <h3>{item.title}</h3>
                  <p>Size: {item.size}</p>
                </div>
                <div className="order-summary-quantity">
                  Qty: <InputNumber min={1} value={item.quantity} onChange={(value) => handleQuantityChange(item.printId, value)} />
                </div>
                <div className="order-summary-price">${(item.price * item.quantity).toFixed(2)}</div>
                <Button type="link" onClick={() => handleRemove(item.printId)}>Remove</Button>
              </List.Item>
            )}
          />
          <div className="order-summary-totals">
            <div className="order-summary-line"><span>Subtotal</span><span>${getCartTotal().toFixed(2)}</span></div>
            <div className="order-summary-line"><span>Tax</span><span>${taxAmount.toFixed(2)}</span></div>
            <div className="order-summary-line"><span>Shipping</span><span>$15.00</span></div>
            <div className="order-summary-total"><span>Total</span><span>${(getCartTotal() + taxAmount + 15).toFixed(2)}</span></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
