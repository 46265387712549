import React from 'react';
import 'lazysizes';
import '../components/css/AboutPage.css';
import AboutHeroImage from '../assets/Images/About.webp';
import AboutContentImage from '../assets/Images/Abouthead2.webp';

const AboutPage = () => (
  <div className="about-page">
    <div className="about-hero" style={{ backgroundImage: `url(${AboutContentImage})` }}>
      <h1>ABOUT</h1>
    </div>
    <div className="about-content">
    <img src={AboutHeroImage} alt="About Content" className="about-image" />
      <div className="about-text">
        <h2>Hi, I'm Colton.</h2>
        <p>Based in Salt Lake City, Utah, I am driven by my love for adventure and a commitment to producing meaningful media that inspires others to explore and pursue their dreams.</p>
        <h3>My Mission</h3>
        <p>I believe in the power of storytelling to evoke change and inspire action. My mission is to craft captivating media that goes beyond superficial content, offering authentic and informative content for outdoor brands and enthusiasts alike.</p>
        <h3>My Vision</h3>
        <p>I am dedicated to creating media that matters - content that not only showcases the beauty of our natural world, but also fosters a deeper connection and appreciation for it. In a world full of distractions, I am committed to producing meaningful work that inspires intentional living, genuine exploration, and respect for our environment.</p>
        <p>Join me on this journey as we celebrate the spirit of exploration and storytelling through the lens of adventure. Let's create something extraordinary together.</p>
      </div>
    </div>
  </div>
);

export default AboutPage;
