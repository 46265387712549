import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../css/Footer.css';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);

  const handleBackToHome = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page if already on the home page
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the location changes to home
    }
  }, [location.pathname]);

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className={`footer ${isFixed ? 'fixed' : ''}`}>
      {location.pathname !== '/' && (
        <button className="back-to-home" onClick={handleBackToHome}>
          Back to Home
        </button>
      )}
      <p>© 2024 Colton Mansavage. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
