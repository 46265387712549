import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { List, Button, Select, Upload, Input, message, Modal, Checkbox } from 'antd';
import Masonry from 'react-masonry-css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SaveOutlined, PushpinOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import '../components/css/CategoryEditor.css';
import api from '../api';

const { Option } = Select;

const ItemType = {
  IMAGE: 'image',
};

const DraggableImage = ({ image, index, moveImage, onSelectImage }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemType.IMAGE,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.IMAGE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div onClick={() => onSelectImage(image._id)}>
        <img
          alt={image.description || 'No description'}
          src={api.getImageUrl(image.filename)}
        />
        <div className="image-description-overlay">
          {image.description || 'No description'}
        </div>
      </div>
    </div>
  );
};

const CategoryEditor = () => {
  const { categoryId } = useParams();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categoryId);
  const [images, setImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedImagesForDeletion, setSelectedImagesForDeletion] = useState([]);
  const [isThumbnailModalVisible, setIsThumbnailModalVisible] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await api.fetchCategories();
        setCategories(res);
      } catch (err) {
        console.error('Error fetching categories', err);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const fetchImages = async () => {
        try {
          const res = await api.fetchImages(selectedCategory);
          const sortedImages = res.sort((a, b) => a.metadata.order - b.metadata.order);
          setImages(sortedImages);
        } catch (err) {
          console.error('Error fetching images', err);
        }
      };

      fetchImages();
    }
  }, [selectedCategory]);

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    // Update the order property based on the new positions
    updatedImages.forEach((image, index) => {
      image.metadata.order = index;
    });

    setImages(updatedImages);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files', file.originFileObj);
      formData.append('descriptions', descriptions[file.uid] || '');
    });
    formData.append('category', selectedCategory);

    try {
      await api.uploadImages(formData);
      message.success('Images uploaded successfully');
      setFileList([]);
      setDescriptions({});
      const res = await api.fetchImages(selectedCategory);
      setImages(res);
    } catch (err) {
      console.error('Error uploading files:', err);
      message.error('Failed to upload images');
    }
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleDescriptionChange = (uid, value) => {
    setDescriptions((prev) => ({ ...prev, [uid]: value }));
  };

  const handleSaveOrder = async () => {
    try {
      await api.saveImageOrder(images);
      message.success('Order saved successfully');
    } catch (err) {
      console.error('Error saving order:', err);
      message.error('Failed to save order');
    }
  };

  const handleDeleteImages = async () => {
    const imageIds = selectedImagesForDeletion;
    try {
      await api.deleteImages(imageIds);
      setImages(images.filter((img) => !imageIds.includes(img._id)));
      message.success('Images deleted successfully');
      setDeleteModalVisible(false);
      setSelectedImagesForDeletion([]);
    } catch (err) {
      console.error('Error deleting images:', err);
      message.error('Failed to delete images');
    }
  };

  const handleSelectImage = (imageId) => {
    setImages((images) => images.map((img) => (img._id === imageId ? { ...img, selected: !img.selected } : img)));
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };

  const handleImageSelectionForDeletion = (imageId) => {
    if (selectedImagesForDeletion.includes(imageId)) {
      setSelectedImagesForDeletion(selectedImagesForDeletion.filter((id) => id !== imageId));
    } else {
      setSelectedImagesForDeletion([...selectedImagesForDeletion, imageId]);
    }
  };

  const handleSetThumbnail = async () => {
    if (!selectedThumbnail) return;
    try {
      await api.setThumbnail(selectedCategory, selectedThumbnail);
      message.success('Thumbnail set successfully');
      setIsThumbnailModalVisible(false);
    } catch (err) {
      console.error('Error setting thumbnail:', err);
      message.error('Failed to set thumbnail');
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <div className="category-editor">
      <div className="header-container">
        <h1 className="header-title">Category Editor</h1>
        <div className="button-container">
          <Button type="primary" icon={<PushpinOutlined />} onClick={() => setIsThumbnailModalVisible(true)}>
            Select Thumbnail for Category
          </Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={handleSaveOrder}>
            Save Order
          </Button>
          <Button type="primary" icon={<DeleteOutlined />} onClick={toggleDeleteModal}>
            Select and Delete Images
          </Button>
        </div>
      </div>
      <div className="category-select">
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          style={{ width: '100%', marginBottom: '20px' }}
        >
          {categories.map((category) => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>
      </div>
      <DndProvider backend={HTML5Backend}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {images.map((image, index) => (
            <DraggableImage
              key={image._id}
              index={index}
              image={image}
              moveImage={moveImage}
              onSelectImage={handleSelectImage}
            />
          ))}
        </Masonry>
      </DndProvider>
      <div className="upload-container">
        <h2>Add Photos</h2>
        <div className="upload-form">
          <Upload
            multiple
            listType="picture"
            fileList={fileList}
            onChange={handleFileChange}
          >
            <Button icon={<PlusOutlined />} className="select-files-btn">
              Select Files
            </Button>
          </Upload>
          {fileList.map((file) => (
            <div key={file.uid} className="upload-item">
              <img src={URL.createObjectURL(file.originFileObj)} alt="Preview" className="upload-image-preview" />
              <Input
                placeholder="Add description"
                value={descriptions[file.uid] || ''}
                onChange={(e) => handleDescriptionChange(file.uid, e.target.value)}
              />
            </div>
          ))}
        </div>
        <div className="upload-button">
          <Button type="primary" onClick={handleUpload} className="upload-btn">
            Upload
          </Button>
        </div>
      </div>
      <Modal
        title="Select Thumbnail"
        visible={isThumbnailModalVisible}
        onOk={handleSetThumbnail}
        onCancel={() => setIsThumbnailModalVisible(false)}
      >
        <List
          dataSource={images}
          renderItem={(item) => (
            <List.Item>
              <Checkbox checked={item._id === selectedThumbnail} onChange={() => setSelectedThumbnail(item._id)} />
              <img
                alt={item.metadata.description}
                src={api.getImageUrl(item.filename)}
                className="thumbnail-image"
              />
              <div className="thumbnail-description">{item.metadata.description}</div>
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        title="Select Images to Delete"
        visible={deleteModalVisible}
        onOk={handleDeleteImages}
        onCancel={toggleDeleteModal}
        okText={`Delete ${selectedImagesForDeletion.length} Images`}
      >
        <List
          itemLayout="horizontal"
          dataSource={images}
          renderItem={(image) => (
            <List.Item>
              <Checkbox
                checked={selectedImagesForDeletion.includes(image._id)}
                onChange={() => handleImageSelectionForDeletion(image._id)}
              >
                <img
                  alt={image.description || 'No description'}
                  src={api.getImageUrl(image.filename)}
                  className="gallery-image-small"
                />
                <span className="thumbnail-description">{image.description || 'No description'}</span>
              </Checkbox>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default CategoryEditor;
