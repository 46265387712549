import React, { useEffect, useRef } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset'; // Import lazysizes and bgset plugin
import '../css/Hero2.css'; // Import specific styles for Hero2
import backgroundImage from '../../assets/Images/Dark.png'; // Ensure this path is correct

const Hero2 = () => {
  const heroRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const offsetTop = heroRef.current.offsetTop;
      const offsetHeight = heroRef.current.offsetHeight;

      if (scrollY + window.innerHeight > offsetTop && scrollY < offsetTop + offsetHeight) {
        const percentage = (scrollY + window.innerHeight - offsetTop) / (window.innerHeight + offsetHeight);
        heroRef.current.style.transform = `scale(${1 + percentage * 0.9})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="hero2-container">
      <div className="hero2" ref={heroRef} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="hero2-content">
          <p>Hi! I'm Colton. A photographer, filmmaker, and adventure enthusiast.</p>
        </div>
      </div>
    </div>
  );
};

export default Hero2;
