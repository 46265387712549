import React from 'react';
import HeroSection from '../components/HeroSection/index';
import Hero2 from '../components/Hero2/index.jsx';
import ServicesSection from '../components/ServicesSection/index';
import Gallery from '../components/Gallery/index';
import ContactCTA from '../components/ContactCTA/index'; // Adjust the path as necessary




const HomePage = () => (
  <div>
    <HeroSection />
    <Hero2 />
    <Gallery />
    <ServicesSection />
    <ContactCTA />

    

  </div>
);

export default HomePage;
