import React, { useState, useEffect } from 'react';
import '../components/css/Film.css';

const FilmPage = () => {
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (index, orientation) => {
    setExpanded(expanded === index ? null : index);
    const videoElements = document.querySelectorAll('iframe');
    videoElements.forEach((videoElement, idx) => {
      const src = videoElement.src.replace("?autoplay=1", "");
      if (expanded === index) {
        videoElement.src = src;
      } else if (index === `video-${idx}`) {
        videoElement.src = `${src}?autoplay=1`;
      } else {
        videoElement.src = src;
      }
    });
  };

  const handleClose = (index) => {
    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement) {
      // Pause YouTube videos
      if (videoElement.src.includes('youtube.com')) {
        const player = new window.YT.Player(videoElement);
        player.pauseVideo();
      }
      // Pause Facebook videos
      if (videoElement.src.includes('facebook.com')) {
        const player = new window.FB.Player(videoElement);
        player.pause();
      }
    }
    setExpanded(null);
  };

  useEffect(() => {
    // Load YouTube API
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Load Facebook API
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: 'your-app-id', // Replace with your Facebook app ID
        xfbml: true,
        version: 'v3.2'
      });
    };
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  return (
    <div className="film-page">
      <div className="film-hero">
        <h1>FILM</h1>
      </div>
      <div className="film-content">
        <div className="film-description">
        </div>
        <h2 className="section-title">Longer Films</h2>
        <div className="film-grid horizontal-grid">
          {[
            'https://player.vimeo.com/video/908448144',
            'https://www.youtube.com/embed/dFb9rtZIGU0?si=3pKhoZsu7ZswgxbZ',
            'https://www.youtube.com/embed/bhGzqJ3MaI0',
            'https://cdnapisec.kaltura.com/p/1660902/sp/166090200/embedIframeJs/uiconf_id/25717641/partner_id/1660902?iframeembed=true&playerId=kaltura_player&entry_id=1_4wadv0e2&flashvars[streamerType]=auto&flashvars[localizationCode]=en_US&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[chapters.thumbnailRotator]=false&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&wid=1_5hi6qqjt',
            'https://www.youtube.com/embed/VG8H1dhiQn0?si=DxND42IC0TsF_fHh'
          ].map((src, index) => (
            <div 
              key={index}
              className={`film-item ${expanded === `video-long-${index}` ? `expanded landscape` : ''}`} 
              onClick={() => handleExpand(`video-long-${index}`, 'landscape')}
            >
              <iframe 
                src={src}
                width="640" 
                height="360" 
                frameBorder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen
                title={`Video ${index + 1}`}
              ></iframe>
              {expanded === `video-long-${index}` && (
                <button className="close-button" onClick={(e) => { e.stopPropagation(); handleClose(`video-long-${index}`); }}>X</button>
              )}
            </div>
          ))}
        </div>
        <h2 className="section-title">Short Films and Reels</h2>
        <div className="film-grid vertical-grid">
          {[
            '214085060621622', 
            '2698391560306764', 
            'Jvs4HBk1Ma4', 
            '9nGu18OX8bY', 
            'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FUWMadisonLS%2Fvideos%2F516535406748483%2F%3Fref%3Dembed_video&show_text=0&width=267'
          ].map((videoId, index) => (
            <div 
              key={index}
              className={`film-item ${expanded === `video-${index}` ? `expanded portrait` : ''}`} 
              onClick={() => handleExpand(`video-${index}`, 'portrait')}
            >
              <iframe 
                id={`video-${index}`}
                src={
                  index < 2 
                    ? `https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/watch/?v=${videoId}` 
                    : index === 4 
                    ? videoId 
                    : `https://www.youtube.com/embed/${videoId}`
                }
                width="320" 
                height="480" 
                frameBorder="0" 
                allowFullScreen 
                title={`Video ${index + 1}`}
                onClick={(e) => { e.stopPropagation(); handleExpand(`video-${index}`, 'portrait'); }}
              ></iframe>
              {expanded === `video-${index}` && (
                <button className="close-button" onClick={(e) => { e.stopPropagation(); handleClose(`video-${index}`); }}>X</button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilmPage;
