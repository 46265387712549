import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Button, Modal, InputNumber, message } from 'antd';
import '../components/css/PrintsDetail.css';
import sizePrices from '../../../server/config/sizePrices.json';
import { useCart } from '../components/CartContext';
import api from '../api';

const { Option } = Select;

const PrintDetails = () => {
  const { printId } = useParams();
  const [print, setPrint] = useState(null);
  const [size, setSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [sizeGuideVisible, setSizeGuideVisible] = useState(false);
  const { addToCart } = useCart();
  const [addedToCart, setAddedToCart] = useState(false); // Track added to cart state

  useEffect(() => {
    const fetchPrintDetails = async () => {
      try {
        const res = await api.fetchPrintById(printId);
        setPrint(res);
      } catch (err) {
        console.error('Error fetching print details', err);
      }
    };

    fetchPrintDetails();
  }, [printId]);

  const showSizeGuide = () => {
    setSizeGuideVisible(true);
  };

  const hideSizeGuide = () => {
    setSizeGuideVisible(false);
  };

  const handleAddToCart = () => {
    if (!size) {
      message.error('Please select a size');
      return;
    }

    const price = sizePrices[size] * quantity;
    const variantId = print.variantIds[size.toUpperCase()];
    if (!variantId) {
      message.error('Variant ID not found for selected size');
      return;
    }
     // Get the variant ID based on the selected size
    const cartItem = {
      printId: print._id,
      title: print.title,
      size,
      quantity,
      price,
      filename: print.filename,
      printfulProductId: print.printfulProductId, // Include this
      printfulVariantId: variantId,  // Use the mapping
    };

    console.log('Adding to cart:', cartItem); // Debugging
    addToCart(cartItem);
    setAddedToCart(true); // Set state to true when added to cart
    message.success('Added to cart');
  };

  if (!print) {
    return <div>Loading...</div>;
  }

  return (
    <div className="print-details">
      <div className="print-details-content">
        <div className="print-image-container">
          <img
            src={api.getPrintImageUrl(print.filename)}
            alt={print.description}
            className="print-image"
            // width="500" // Set an appropriate width
            // height="600" // Set an appropriate height
          />
        </div>
        <div className="print-info">
          <h1>{print.title}</h1>
          <p>from $60.00</p>
          <div className="size-selection">
            <label className="size-label">Size:</label>
            <Select
              placeholder="Select Size"
              style={{ width: 200 }}
              onChange={(value) => setSize(value)}
            >
              {Object.keys(sizePrices).map((size) => (
                <Option key={size} value={size}>
                  {size.toUpperCase()} - ${sizePrices[size]}
                </Option>
              ))}
            </Select>
            <Button type="link" onClick={showSizeGuide}>
              Size Guide
            </Button>
          </div>
          <div className="quantity-selection">
            <label className="quantity-label">Quantity:</label>
            <InputNumber
              min={1}
              value={quantity}
              onChange={(value) => setQuantity(value)}
            />
          </div>
          <Button
            type="primary"
            style={{ marginTop: '30px', height: '45px', fontSize: '25px' }}
            onClick={handleAddToCart}
          >
            {addedToCart ? 'Added' : 'Add To Cart'} {/* Change button text based on state */}
          </Button>
          <Modal
            title="Size Guide"
            open={sizeGuideVisible}
            onOk={hideSizeGuide}
            footer={[
              <Button key="ok" type="primary" onClick={hideSizeGuide}>
                OK
              </Button>
            ]}
          >
          
            <p><strong>X-Small:</strong>  5" x 7"</p>
            <p><strong>Small:</strong> 8" x 10"</p>
            <p><strong>Medium:</strong> 11" x 14"</p>
            <p><strong>Large:</strong> 16" x 20"</p>
            <p><strong>X-Large:</strong> 24" x 36"</p>
          </Modal>
        </div>
      </div>
      <div className="print-description">
        <p>{print.description}</p>
      </div>
    </div>
  );
};

export default PrintDetails;
